.popover {
    background-color: #82ae33;
    color: #ffffff;
    font-size: 1.0178571429rem;
    max-width: 400px !important;

    @include media-breakpoint-down(sm) {
        max-width: 276px;
    }

    .popover-header {
        background-color: #82ae33;
        color: #ffffff;
    }
    .popover-body {
        background-color: #82ae33;
        color: #ffffff;
    }
}

.bs-popover-right > .arrow::after {
    border-right-color: #82ae33 !important;
}
