.rangeslider {
    box-shadow: none;

    &__handle {
        background-image: none;
        text-align: center;
        height: 38px;
        width: 145px;
        color: $cool-secondary;
        line-height: 19px;
        font-size: 1rem;
        font-weight: 500;
        background-color: $white;
        border: 1px solid $cool-secondary;
        border-radius: 4px;
        box-shadow: 0px 4px 6px rgba($cool-secondary, 0.25);
        padding-top: 9px;
        padding-left: 9px;
        padding-right: 9px;
        position: absolute;

        &:before {
            font-family: '#{$icomoon-font-family}' !important;
            content: '\e901';
            transform: rotate(90deg);
            position: absolute;
            left: 5px;
        }
        &:after {
            border-radius: unset;
            background-image: none;
            top: unset;
            bottom: unset;
            left: unset;
            display: unset;
            width: unset;
            height: unset;
            font-family: '#{$icomoon-font-family}' !important;
            content: '\e901';
            transform: rotate(-90deg);
            position: absolute;
            right: 5px;
        }
    }

    &__fill {
        background-color: $cool-secondary;
    }

    &--horizontal {
        height: 10px;
        width: 85%;
        margin-left: 7.5%;

        @include media-breakpoint-down(md) {
            width: 78%;
            margin-left: 11%;
        }

        @include media-breakpoint-down(sm) {
            width: 75%;
            margin-left: 12.5%;
        }
    }
}

.rangeslider--horizontal .rangeslider__handle {
    top: -14px;
}

.range-info {
    color: $cool-secondary;
    position: absolute;
    top: 25px;
    left: 0;

    &--to {
        left: unset;
        right: 0;
    }
}

.section-apply,
.section-intro {
    & .tooltip {
        position: absolute;
        bottom: 0;
        transform: translateX(45%);
    }
}
