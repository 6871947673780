$container-width: 70px;
$prefix-padding-horizontal: 12px;
$option-padding: 6px;
$prefix-padding-vertical: 6px;

.select2 {
    &.select2-container {
        width: $container-width !important;

        & .select2-selection {
            background-color: transparent;
            border: none;
            color: #fff;
            font-size: 15px;
            font-weight: 600;
            line-height: 19px;
        }

        & .select2-selection__rendered {
            color: #fff;
        }

        & .select2-selection__arrow > b {
            border-color: #fff transparent transparent;
        }

        &.select2-container--open {
            & .select2-selection__arrow > b {
                border-color: transparent transparent #fff;
            }
        }
    }

    &-search {
        display: none;
    }
}

.select2-dropdown {
    width: calc($container-width + 2 * $prefix-padding-horizontal - 1px) !important;
    border: none;
    color: #fff;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    background-color: $cool-primary;
    overflow: hidden;
    left: -$prefix-padding-horizontal !important;
    top: calc($prefix-padding-vertical + $option-padding);

    .select2-results__option {
        background-color: rgba(55, 41, 43, 0.17) !important;
        font-size: 15px;
    }

    .select2-results__option--highlighted {
        background-color: rgba(55, 41, 43, 0.4) !important;
    }

    .select2-results__option--selected {
        background-color: rgba(100, 100, 100, 0.75) !important;
    }
}
