.text {
    font-weight: 400;

    &--h1 {
        font-size: 32px;
    }

    &--h2 {
        font-size: 28px;
        margin-bottom: 0px;
    }

    &--h3 {
        font-size: 26px;
        font-weight: 500;
    }

    &--primary {
        color: $cool-primary;
    }

    &--dark {
        color: #707070;
    }

    &--white {
        color: $white;
    }

    &--distinct {
        font-weight: 700;
    }

    &--small {
        font-size: 14px;
    }
}
