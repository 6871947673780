.section-youtube {
    h2 {
        color: $cc-green;
    }

    &__carousel {
        width: 100% !important;
        margin: 0 auto !important;

        @include media-breakpoint-up(md) {
            width: 80% !important;
        }
    }

    .carousel-control-next {
        &-icon {
            width: 35px;
            height: 35px;
        }
    }

    .carousel-control-prev {
        &-icon {
            width: 35px;
            height: 35px;
        }
    }
}
