$cool-primary: #70b635;
$cool-secondary: #81007f;
$cool-third: #f4f5f8;

$text-primary: #585a5d;
$light-gray: #f2f2f2;
$medium-gray: #8c9199;
$cool-grey: #707070;
$section-info-text: #8c9199;

$slider-color: #d0d0d0;

$stupid-color: #c32d2c;
$semi-transparent-white: rgba(255, 255, 255, 0.55);

// New Design
$dark-purple: #81007f;
$darker-purple: #5a0058;
$darker-orange: #e18c18;
$dark-orange: #ff9f1b;
$soft-orange: #ffbb32;
$cc-green-soft: #98c052;
$cc-green: #82ae33;
$cc-green-darker: #7da92f;
$red: #b81a1a;
$dark-red: #c32d2c;
$darker-red: #9e1d1c;
$main-button-color: #c32d2c;
$lighter-black: #3b3b3b;
$black: #37292b;
