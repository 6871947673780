.slider {
    &-wrap {
        position: relative;
        margin-bottom: 80px;

        @include media-breakpoint-down(sm) {
            margin-bottom: 40px;
        }
    }
}
