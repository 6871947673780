.compare-table-wrapper {
    overflow-x: scroll;

    &::-webkit-scrollbar {
        display: none;
    }
}
.section-compare__table {
    @extend .w-100, .mt-4;
    text-align: center;
    color: $text-primary;
    font-weight: 500;

    @include media-breakpoint-up(lg) {
        font-size: 1.3rem;
    }

    @include media-breakpoint-down(sm) {
        font-size: 0.875rem;
    }

    thead {
        th {
            text-align: center;
            font-weight: normal;
        }
    }

    tbody {
        td {
            padding: 0.6rem 0;
        }

        tr td > div {
            display: inline-block;

            &.tooltipbox {
                justify-content: flex-end;
                &.tooltipbox__text {
                    justify-content: flex-end;
                }
            }
        }
        > tr:nth-child(odd) {
            background-color: $light-gray;
        }
    }
    tbody,
    thead {
        tr > td:nth-child(1) {
            text-align: right;
        }
        tr > th:nth-child(1) {
            text-align: right;
        }
    }
    .compare-table__logo {
        padding-bottom: 2.6rem;
    }
    .compare-table__icon {
        background-size: cover;
        background-position: center;
    }
    img {
        max-height: 3rem;
        width: auto;
        display: inline-block;
        height: auto;
        max-width: 100%;
        vertical-align: middle;
    }
}
