.section-faq {
    background: $light-gray;
    font-weight: 600;
    position: relative;
    padding-bottom: 5rem;

    @include media-breakpoint-up(md) {
        font-size: 1.125rem;
    }

    @include media-breakpoint-up(lg) {
        font-size: 1rem;
    }

    h2 {
        color: $cc-green;
    }

    h3 {
        margin-bottom: 15px;
        font-size: 1.125rem;

        @include media-breakpoint-up(md) {
            font-size: 1.4rem;
        }

        @include media-breakpoint-up(lg) {
            font-size: 1.125rem;
        }
    }

    ul {
        margin: 0;
        padding: 0;
        list-style: none;

        @include media-breakpoint-up(md) {
            margin: 0 0 0 -20px;
        }

        li {
            list-style: none;

            ul {
                margin: 0;
            }

            > a {
                color: $cc-green;
                text-decoration: underline;
            }

            > a.li-href {
                text-decoration: none;
                display: block;
                font-weight: 600;
                color: black;
                line-height: 1.2;
                padding: 7px 0;

                @include media-breakpoint-up(md) {
                    padding: 12px 20px;
                }

                &:hover {
                    background: #fff;
                }
            }

            &[aria-expanded='true'] {
                background: #fff;
            }

            .is-submenu-item {
                font-weight: normal;
                line-height: 1.4;
                background: #fff;

                @include media-breakpoint-up(md) {
                    padding: 6px 20px 20px 20px;
                }
            }
        }
    }
}

.arrow {
    width: 100px;
    height: 100px;
    background: #fff url('../../../images/ico-arrow-up.svg') 50% 50% no-repeat;
    background-size: auto 35px;
    position: absolute;
    bottom: 50%;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 50%;
}

.arrow-wrapper {
    width: 100%;
    position: relative;
    height: 100px;
    background-color: #f4f5f8;
    margin-bottom: -40px;
}
