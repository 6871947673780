#intro {
    padding-top: 2.8rem;
    padding-bottom: 1.875rem;
    background: $cc-green;
    color: #fff;
    width: 100%;
    overflow: hidden;
    position: relative;
    margin-top: 4.375rem;
    display: flex;
    align-items: center;
    justify-content: center;

    h1 {
        font-weight: 700;

        @include media-breakpoint-down(sm) {
            font-size: 1.75rem;
        }
    }

    .intro-subtitle {
        font-size: 2rem;
        font-weight: 500;

        @include media-breakpoint-down(sm) {
            font-size: 1.625rem;
        }
    }

    // Every form Intro
    &.form-intro {
        padding-top: 1.75rem;
        padding-bottom: 5px;

        p {
            font-size: 1.25rem;

            a {
                color: $dark-orange;
                font-weight: bold;
                text-decoration: underline;
            }
        }

        @include media-breakpoint-down(sm) {
            padding-bottom: 1.25rem;
            padding-top: 0.75rem;
        }

        @include media-breakpoint-up(md) {
            padding-top: 1.875rem;
            padding-bottom: 1.875rem;
        }
    }

    @include media-breakpoint-up(lg) {
        padding-top: 3.75rem;
        padding-bottom: 4.375rem;
    }

    h1 {
        text-transform: uppercase;
        margin-bottom: 0.625rem;

        @include media-breakpoint-up(md) {
            font-size: 2.813rem;
        }

        @include media-breakpoint-up(lg) {
            margin-bottom: 0;
        }

        & + p {
            font-weight: 300 !important;
            font-size: 1.25rem !important;

            @include media-breakpoint-up(md) {
                font-size: 1.875rem !important;
            }
        }
    }

    p {
        text-transform: none !important;
    }

    h2 {
        margin-top: 1.25rem;
        color: white;
        text-transform: none;
        font-size: 1.5rem;

        span {
            color: $dark-purple;
        }

        @include media-breakpoint-up(md) {
            font-size: 2.375rem;
        }
    }

    .button {
        &.main {
            margin-bottom: 3.125rem;
            margin-left: auto;
            margin-right: auto;
            @include media-breakpoint-up(md) {
                margin-bottom: 4.375rem;
            }

            @include media-breakpoint-up(lg) {
                margin-bottom: 1.875rem;
            }
        }
    }
}

.stripes {
    @include media-breakpoint-up(lg) {
        &:before,
        &:after {
            display: block;
            content: '';
            position: absolute;
            margin-top: -73px;
            width: 0;
            height: 0;
            border-style: solid;
            z-index: 1;

            @media screen and (min-width: 2000px) {
                margin-top: -173px;
            }
        }

        &:before {
            right: 50%;
            border-width: 100px 0 100px 1000px;
            border-color: transparent transparent transparent $cc-green-darker;

            @media screen and (min-width: 2000px) {
                border-width: 200px 0 200px 2000px;
            }
        }

        &:after {
            left: 50%;
            border-width: 100px 1000px 100px 0;
            border-color: transparent $cc-green-darker transparent transparent;

            @media screen and (min-width: 2000px) {
                border-width: 200px 2000px 200px 0;
            }
        }
    }
}
