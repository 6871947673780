.section-ratings {
    background-color: $light-gray;
    h2 {
        color: $cc-green;
    }

    &.section-white {
        .btn-default {
            background-color: $light-gray;
        }
    }

    .ratings {
        margin-top: 1.875rem;

        .percents {
            > div {
                display: inline-block;
                font-weight: bold;
            }

            .left {
                color: $lighter-black;
                font-size: 4rem;
            }

            .right {
                margin-left: 5px;
                color: $cc-green;
                text-transform: uppercase;
                font-size: 1.5rem;
                line-height: 1.875rem;
            }
        }

        .stars {
            > div {
                display: inline-block;
            }

            .left {
                i.fa {
                    font-size: 2.813rem;
                    color: #ffde00;
                }
            }

            .right {
                margin-left: 5px;
                color: $lighter-black;
                font-size: 1.125rem;
                line-height: 1.5rem;
            }
        }

        .texts {
            padding-top: 0.625rem;
            min-height: 8.75rem;
            color: $lighter-black;

            .header {
                font-weight: bold;
                line-height: 2.5rem;

                .quoute-mark {
                    vertical-align: middle;
                    font-size: 3.75rem;
                }

                .name,
                .age {
                    font-style: italic;
                }
            }

            .text {
                font-style: italic;
            }
        }

        .quoute-slider {
            margin-top: 1.875rem;
            text-align: right;

            button {
                font-weight: 600;
                color: gray;
                text-transform: uppercase;
                padding: 10px 16px;
                border: none;
                border-radius: 8px;
            }

            .prev {
                background-image: url('../../../images/ico-slider-left-gray.png');
                background-position: center center;
                background-repeat: no-repeat;
                background-size: auto 1.25rem;
            }

            .next {
                padding-right: 40px;
                background-image: url('../../../images/ico-slider-right-gray.png');
                background-position: 93% center;
                background-repeat: no-repeat;
                background-size: auto 1.25rem;
            }
        }
    }
}
