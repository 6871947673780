.ico-check {
    width: 2.125rem;
    height: 2.125rem;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

    &--yes {
        background-image: url('../../../images/ico-check.png');
    }
    &--no {
        background-image: url('../../../images/ico-no.svg');
    }
    &--hint {
        background-image: url('../../../images/ico-hint.svg');
    }
}

.info {
    display: inline-block;
    width: 1rem;
    height: 1rem;
    vertical-align: top;
    background: url('../../../images/ico-questionmark-green.png') center center no-repeat;
    background-size: 100% auto;
    position: relative;
    cursor: pointer;
    outline: none;
    border: none;
}
