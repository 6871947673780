.sections {
    padding-bottom: 80px;

    &--white {
        background-color: $white;
    }

    &--primary {
        background-color: $cool-primary;
    }

    &--secondary {
        background-color: $cool-secondary;
    }

    &--third {
        background-color: $cool-third;
    }

    &--cta {
        padding-top: 50px;
        padding-bottom: 50px;
        border-radius: 4px;
        box-shadow: 0 3px 12px rgba(#235f08, 20%);
    }

    &--out {
        position: relative;
        top: -90px;
    }

    &--sms {
        font-size: 16px;
    }
}

.section {
    padding: 3.125rem 0 3.125rem 0;

    h2 {
        font-weight: 600;
        font-size: 1.5rem;
        margin-bottom: 1rem;

        @include media-breakpoint-up(sm) {
            font-size: 1.75rem;
        }

        @include media-breakpoint-up(md) {
            font-size: 2rem;
            font-weight: 700;
            margin-bottom: 2rem;
        }

        @include media-breakpoint-up(lg) {
            font-size: 2.25rem;
        }
    }

    h3 {
        color: $cc-green;
        font-size: 1.3rem;
    }
}

.section-last {
    padding-bottom: 5rem;
}
