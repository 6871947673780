.tooltip {
    opacity: 1;
    display: inline-block;
    position: relative;
    left: 29%;
    margin-bottom: 5px;
    background-color: $white;
    color: $cool-secondary;
    font-weight: 600;
    padding: 10px;
    border-radius: 4px;
    border: 1px solid $cool-secondary;
    transform: translateX(10%);

    &:after {
        content: '';
        display: block;
        position: absolute;
        margin-left: -10px;
        left: 50%;
        bottom: -10px;
        border-style: solid;
        border-width: 10px 10px 0 10px;
        border-color: $cool-secondary transparent transparent transparent;
    }
    &:before {
        z-index: 1;
        content: '';
        display: block;
        position: absolute;
        margin-left: -10px;
        left: 50%;
        bottom: -9px;
        border-style: solid;
        border-width: 10px 10px 0 10px;
        border-color: $white transparent transparent transparent;
    }

    &-wrap {
        display: none;
        position: absolute;
        bottom: 35px;
        left: 0;
        right: 0;
    }
}
