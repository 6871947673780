$logo-width: 160px;
$logo-height: 40px;
$logo-width-mobile: 110px;
$logo-height-mobile: 34px;

.logo {
    background: url('../../../images/redesign/logo-cool-credit.svg') no-repeat;
    background-size: contain;
    width: $logo-width;
    height: $logo-height;

    &.dev {
        background: url('../../../images/redesign/logo-cool-credit-dev.svg') no-repeat;
        animation: run 5s linear infinite;
        background-size: contain;

        @include media-breakpoint-down(sm) {
            animation: none;
        }
    }

    @keyframes run {
        0% {
            margin-left: 0;
        }
        50% {
            margin-left: 100px;
        }
        100% {
            margin-left: 0;
        }
    }

    @include media-breakpoint-down(sm) {
        width: $logo-width-mobile;
        height: $logo-height-mobile;
    }

    & a {
        display: block;
        width: $logo-width;
        height: $logo-height;

        @include media-breakpoint-down(sm) {
            width: $logo-width-mobile;
            height: $logo-height-mobile;
        }
    }

    &--footer {
        @include media-breakpoint-down(sm) {
            margin: 0 auto;
        }
    }
}
