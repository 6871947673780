.form-error {
    display: none;
    color: $white;
    text-transform: uppercase;
    text-align: center;
    padding: 3px;
    margin-top: 0;
    margin-bottom: 0;
    background-color: #c32d2c;
    font-size: 0.625rem;

    &.is-visible {
        display: block;
    }
}

.form-success {
    display: none;
    background: #98c052;
    color: $white;
    text-transform: uppercase;
    text-align: center;
    padding: 3px;
    margin-top: 0;
    margin-bottom: 0;
    font-size: 0.625rem;

    &.is-visible {
        display: block;
    }
}
