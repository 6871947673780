.banks__box {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;

    figure {
        padding-left: 25px;
        padding-right: 25px;

        img {
            width: 140px;
        }
    }
}
