$header-height: 70px;

header {
    position: fixed;
    z-index: 1000;
    width: 100%;
    background-color: transparent;
    padding-left: 40px;
    padding-right: 40px;
    padding-top: 15px;
    padding-bottom: 15px;
    transition: all 0.4s ease-in;
    top: 0;

    &.active {
        background-color: $white;
        box-shadow: 0 1px 5px rgba(0, 0, 0, 0.25);
    }

    @include media-breakpoint-down(sm) {
        padding-left: 10px;
        padding-right: 10px;
        background-color: $semi-transparent-white;
    }

    @media (max-width: 390px) {
        #contact-li {
            width: 0;
            display: none;
        }
    }
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &__links {
        list-style: none;
        margin-bottom: 0;
        display: flex;
        align-items: center;
        gap: 40px;

        @include media-breakpoint-down(sm) {
            padding-left: 0;
        }

        .vertical-line {
            width: 1px;
            height: 25px;
            background-color: $dark-purple;
        }

        li {
            line-height: unset;

            @include media-breakpoint-down(sm) {
                margin-left: 6px;
                padding-left: 0;
            }
        }
    }
}
