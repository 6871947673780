.clock {
    margin: 30px auto 0 auto;
    width: 520px;
    height: 130px; /* 1:4  */

    @media screen and (max-width: 600px) {
        width: 400px;
        height: 100px;
    }

    @media screen and (max-width: 450px) {
        width: 300px;
        height: 75px;
    }

    .time {
        height: 100%;
        width: 25%;
        background-color: white;
        border-radius: 10px;
        float: left;
        line-height: 140px;
        color: $dark-purple;
        font-weight: bold;
        font-size: 93px;

        &.seconds {
            color: $cc-green;
        }

        @media screen and (max-width: 600px) {
            line-height: 100px;
            font-size: 75px;
        }

        @media screen and (max-width: 450px) {
            line-height: 75px;
            font-size: 55px;
        }
    }

    .when-time {
        height: 100%;
        width: 25%;
        background-color: white;
        border-radius: 10px;
        border: 1px solid $cc-green;
        float: left;
        line-height: 140px;
        color: $dark-purple;
        font-weight: bold;
        font-size: 93px;

        &.seconds {
            color: $cc-green;
        }

        @media screen and (max-width: 600px) {
            line-height: 100px;
            font-size: 75px;
        }

        @media screen and (max-width: 450px) {
            line-height: 75px;
            font-size: 55px;
        }
    }

    .space {
        position: relative;
        height: 100%;
        width: 12.5%;
        float: left;

        span {
            position: absolute;
            display: block;
            background-color: white;
            border-radius: 50%;
            width: 20px;
            padding-bottom: 20px;

            @media screen and (max-width: 450px) {
                width: 10px;
                padding-bottom: 10px;
            }
        }

        span.first {
            top: calc(50% - 30px);
            left: calc(50% - 10px);

            @media screen and (max-width: 450px) {
                top: calc(50% - 15px);
                left: calc(50% - 5px);
            }
        }

        span.second {
            top: calc(50% + 10px);
            left: calc(50% - 10px);

            @media screen and (max-width: 450px) {
                top: calc(50% + 5px);
                left: calc(50% - 5px);
            }
        }
    }
}
