.hero {
    background-image: url('../../../images/redesign/hero/heroDogWinter.webp');
    background-repeat: no-repeat;
    background-position: right top;
    background-size: contain;
    padding-top: 70px;

    @include media-breakpoint-down(sm) {
        background-image: url('../../../images/redesign/hero/snowflakes2023.webp');
        background-size: cover;
    }

    &--subpage {
        @include media-breakpoint-down(sm) {
            background-position: right 40px;
        }
    }

    &__header {
        color: $cool-primary;
        text-transform: uppercase;
        font-size: 2rem;
        margin-top: 40px;
        margin-left: 10%;
        padding-bottom: 50px;

        @include media-breakpoint-down(md) {
            margin-left: 10%;
        }

        @include media-breakpoint-down(sm) {
            font-size: 17px;
            text-align: center;
            line-height: 34px;
            margin-top: 20px;
            margin-left: 0;
            padding-bottom: 10px;
        }

        &--sec {
            color: $cool-secondary;
        }

        .hero-main__break {
            @include media-breakpoint-down(sm) {
                display: none;
            }
        }
    }

    &__discount {
        color: $cool-secondary;
        text-transform: uppercase;
        font-size: 1rem;
        margin-left: 15%;
        padding-bottom: 10px;

        @include media-breakpoint-down(md) {
            margin-left: 10%;
        }

        @include media-breakpoint-down(sm) {
            text-align: center;
            line-height: 34px;
            margin-left: 0;
            padding-bottom: 20px;
        }
    }

    &__subheader {
        margin-top: 40px;
        padding-top: 40px;

        @include media-breakpoint-down(lg) {
            background-color: $white;
        }

        @include media-breakpoint-down(sm) {
            margin-top: 0;
        }
    }
}
