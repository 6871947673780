@use 'sass:color';

@mixin btn($color, $hoverColor) {
    @extend .btn, .btn-lg;
    background-color: $color;
    background-image: url('../../../images/ico-arrow.svg');
    display: inline-block;
    font-size: 1.3rem;
    font-weight: bold;
    border-radius: 50px;
    letter-spacing: normal;
    text-transform: uppercase;
    background-position: right 13px center;
    background-repeat: no-repeat;
    background-size: auto 20px;
    padding: 0.5rem 4.2rem 0.5rem 1.4rem;
    margin-bottom: 20px;
    color: #fff;

    &:focus,
    &:active {
        outline: none;
    }

    &:hover {
        background-color: $hoverColor;
        color: #fff;
    }
}

.btns {
    &__violet {
        @include btn($dark-purple, #670066);
        max-width: 300px;
        margin: 20px auto 0;
    }
    &__red {
        @include btn($main-button-color, #9c2423);
        margin: 0 auto;
        max-width: 300px;
        position: relative;
        z-index: 2;
    }

    &__white {
        background-color: #fff;
    }

    &__link {
        font-size: 1rem;
        font-weight: 700;
        color: $cool-secondary;

        &:hover {
            text-decoration: none;
            color: color.adjust($cool-secondary, $lightness: 5%);
        }

        i {
            padding-right: 5px;
            position: relative;
            top: 1px;
        }
    }

    &__header {
        font-size: 1rem;
        font-weight: 700;
        color: $cool-secondary;

        &:hover {
            text-decoration: none;
            color: color.adjust($cool-secondary, $lightness: 5%);
        }

        i {
            padding-right: 5px;
            position: relative;
            top: 1px;

            &:before {
                color: $cool-secondary;
            }
        }
    }

    &__cta {
        display: inline-block;
        border: none;
        border-radius: 4px;
        background-color: $stupid-color;
        color: $white;
        line-height: 19px;
        font-size: 15px;
        font-weight: 700;
        text-transform: uppercase;
        padding: 15px 60px 15px 60px;
        transition: all 0.4s ease;
        position: relative;
        box-shadow: 0 4px 12px rgba($stupid-color, 40%);

        &[readonly] {
            opacity: 0.25;
            cursor: not-allowed;
        }

        &:after {
            position: absolute;
            content: '→';
            top: 15px;
            right: 30px;
            transition: all 0.4s ease;
        }

        &:hover {
            text-decoration: none;
            background-color: color.adjust($stupid-color, $lightness: 5%);
            color: $white;

            &:after {
                right: 20px;
            }
        }

        &--normal {
            background-color: $cool-secondary;
            box-shadow: 0 4px 12px rgba($cool-secondary, 40%);

            &:hover {
                background-color: color.adjust($cool-secondary, $lightness: 5%);
            }

            &[disabled='disabled'] {
                background-color: #a74da5;
                box-shadow: 0 4px 12px rgba(129, 0, 127, 0.4);
                cursor: not-allowed;

                &:hover {
                    background-color: #a74da5;
                    box-shadow: 0 4px 12px rgba(129, 0, 127, 0.4);
                    cursor: not-allowed;
                }
            }
        }
    }
}
