@use 'sass:color';

footer {
    background-color: $white;
    padding-top: 40px;
}

.footer {
    &__contact {
        font-size: 14px;
        line-height: 20px;
        color: $cool-grey;

        a {
            color: $cool-grey;
        }
    }

    &__links {
        @include media-breakpoint-down(sm) {
            text-align: center;
            padding-top: 25px;
        }
    }

    &__menu {
        @include media-breakpoint-down(sm) {
            text-align: center;
        }

        a {
            color: $cool-primary;
            line-height: 19px;
            font-size: 1rem;
            font-weight: 700;
            text-transform: uppercase;

            &:hover {
                color: color.adjust($cool-primary, $lightness: 5%);
                text-decoration: none;
            }
        }

        &-item {
            padding-left: 0px;

            li {
                list-style: none;

                @include media-breakpoint-down(sm) {
                    text-align: center;
                }

                a {
                    color: $cool-grey;
                    line-height: 2rem;
                    font-size: 15px;
                    font-weight: 500;
                }
            }
        }
    }

    &__minimum {
        max-width: 1074px;
        color: $cool-grey;
        line-height: 24px;
        font-size: 13px;
        font-weight: 500;
        padding-top: 45px;
        padding-bottom: 30px;
        padding-left: 10px;
        padding-right: 10px;
    }

    &__copy {
        padding-top: 45px;
        color: $cool-grey;
        line-height: 24px;
        font-size: 13px;
        font-weight: 500;

        a {
            color: $cool-primary;
        }
    }
}
