.faq {
    &__box {
        margin-bottom: 12px;
        border-radius: 4px;
    }

    &__header {
        color: $cool-secondary;
        text-transform: uppercase;
        line-height: 19px;
        font-weight: 700;
        font-size: 1rem;
    }

    &__question {
        color: $cool-grey;
        font-size: 1rem;
        font-weight: 600;
        line-height: 19px;
        padding-top: 14px;
        padding-bottom: 14px;
        cursor: pointer;

        &:hover {
            color: $cool-grey;
            text-decoration: none;
        }
    }

    &__answer {
        color: $cool-grey;
        font-size: 15px;
        font-weight: 500;
        line-height: 19px;
        padding-top: 14px;
        padding-bottom: 14px;
    }
}
