.call-back-alert {
    position: relative;
    top: -95px;

    & .alert-danger {
        color: #a94442;
        background-color: #f2dede;
        border-color: #ebccd1;
        z-index: 1;
        position: relative;
        margin: auto;
    }

    & .alert-info {
        max-width: unset;
    }

    &.container {
        padding: 0;
    }
}
