.p {
    &top-75 {
        padding-top: 75px;
    }

    &top-sm-15 {
        @include media-breakpoint-down(sm) {
            padding-top: 15px;
        }
    }

    &left-sm-40 {
        @include media-breakpoint-down(sm) {
            padding-left: 40px;
        }
    }

    &bot-info {
        padding-bottom: 195px;
    }
}
