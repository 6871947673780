$discount-input-width: 100px;

.calc {
    &__currency {
        text-transform: none;
    }

    &__container {
        display: flex;
        margin-left: 10%;

        @include media-breakpoint-down(md) {
            margin-left: 5%;
        }

        @include media-breakpoint-down(sm) {
            flex-direction: column;
            margin-left: 0px;
        }
    }

    &__header {
        color: $cool-primary;
        text-align: center;
        font-size: 24px;
        line-height: 29px;
        padding-bottom: 26px;

        @include media-breakpoint-down(sm) {
            font-size: 15px;
            line-height: 1rem;
            padding-bottom: 15px;
        }
    }

    &__promocode-wrapper {
        position: relative;
        display: flex;
        flex-direction: column;
        margin-left: 5px;

        @include media-breakpoint-down(sm) {
            margin-left: 0;
            align-items: center;

            .form-error,
            .form-success {
                width: $discount-input-width;
            }
        }
    }

    &__discount-txt {
        line-height: 19px;
        font-size: 1rem;
        font-weight: 700;
        text-transform: uppercase;
        color: $cool-primary;
        position: relative;
        display: flex;
        gap: 10px;
        justify-content: center;

        @include media-breakpoint-down(sm) {
            flex-direction: column;
            gap: 10px;
            align-items: center;
            margin-top: 1.5rem;
        }

        i.fa {
            cursor: pointer;
            position: absolute;

            &.fa-spinner {
                color: $cool-primary;
                right: -20px;
                top: 4px;
            }

            &.fa-times {
                color: $cool-grey;
                right: -17px;
                top: 3px;
            }
        }
    }

    &__discount-inpt {
        width: $discount-input-width;
        border: none;
        border-bottom: 2px solid #d2d2d2;
        outline: none;
        text-align: center;

        &.valid {
            border-bottom: 2px solid #98c052;
        }

        &.invalid {
            background-color: #f9eaea;
            border-color: #c32d2c;
        }

        &::placeholder {
            color: $cool-grey;
            text-align: center;
        }
    }

    &__calculation {
        background-color: $white;
        box-shadow: 0px 12px 20px rgba($black, 0.2);
        border-radius: 4px;
        width: 575px;
        padding-top: 50px;
        padding-bottom: 50px;
        padding-left: 40px;
        padding-right: 40px;
        position: relative;
        z-index: 3;

        @include media-breakpoint-down(sm) {
            width: 320px;
            padding: 25px 10px;
            margin: 0 auto;
        }
    }

    &__numbers {
        width: 450px;
        margin-top: 125px;
        margin-bottom: 125px;
        background-color: $white;
        box-shadow: 0px 12px 20px rgba($black, 0.2);
        border-radius: 4px;
        padding: 30px;
        position: relative;
        z-index: 2;
        left: -5px;

        @include media-breakpoint-down(md) {
            padding: 20px;
            margin-bottom: 195px;
        }

        @include media-breakpoint-down(sm) {
            width: 300px;
            margin: 0 auto;
            left: 0;
            visibility: hidden;
            height: 0;
            //padding-bottom: 0px;
            top: -255px;
            transition: all 0.4s ease;
        }

        &--block {
            visibility: visible;
            height: unset;
            top: 0;
            //padding-bottom: 50px;

            .calc__cels {
                visibility: visible;
            }
        }

        &--one {
            margin-top: 80px;
            margin-bottom: 80px;

            @include media-breakpoint-down(sm) {
                margin-top: 0;
            }
        }
    }

    &__cels {
        display: flex;
        justify-content: space-between;
        text-transform: uppercase;
        line-height: 30px;
        font-weight: 400;
        color: $cool-grey;

        @include media-breakpoint-down(sm) {
            visibility: hidden;
        }

        &--significant {
            color: $cool-primary;
            font-weight: 700;
        }
    }

    &__promocode-submit {
        border: none;
        background-color: $cool-primary;
        color: #fff;
        border-radius: 4px;
        box-shadow: 0 4px 12px rgba(0, 0, 0, 0.4);
        height: 23px;
    }
}

.promocode-submit-wrapper {
    width: 60px;
}
