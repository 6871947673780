.video-frame {
    display: none;
    background-color: black;
    position: relative;
    padding-bottom: 51.7%; /* 56.25 = 16:9, 51.7 to fit exact size of the CC video ratio */
    padding-top: 25px;
    height: 0;
    /* border: 10px solid $dark-orange; */
    //border-radius: $global-radius;

    &.is-active {
        display: block;
    }

    & > iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100% !important;
    }

    & > .fake-iframe {
        cursor: pointer;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-position: center;
        background-size: cover;

        &--how {
            background-image: url('../../../images/redesign/jak.webp');
        }

        &--licence {
            background-image: url('../../../images/redesign/licence.webp');
        }
    }

    .fake-iframe:hover > .fake-play-button {
        background: red;
    }

    .fake-play-button {
        cursor: pointer;
        background: rgba(0, 0, 0, 1);
        border-radius: 50% / 10%;
        color: #ffffff;
        font-size: 14px; /* change this to change size of the button */
        height: 3em;
        margin: 5px;
        padding: 0;
        position: relative;
        top: calc(50% - 22px);
        left: calc(50% - 30px);
        text-align: center;
        text-indent: 0.1em;
        width: 4em;
        -webkit-transition: all;
        -moz-transition: all;
        -o-transition: all;
        transition: all;

        &:hover {
            background: red;
        }

        &::before {
            background: inherit;
            border-radius: 5% / 50%;
            bottom: 9%;
            content: '';
            left: -5%;
            position: absolute;
            right: -5%;
            top: 9%;
        }

        &::after {
            border-style: solid;
            border-width: 1em 0 1em 1.732em;
            border-color: transparent transparent transparent rgba(255, 255, 255, 0.95);
            content: ' ';
            font-size: 0.75em;
            height: 0;
            margin: -1em 0 0 -0.75em;
            top: 50%;
            position: absolute;
            width: 0;
        }
    }
}
