.links {
    color: $cool-primary;
    text-decoration: underline;

    &:hover {
        color: $cool-primary;
        text-decoration: none;
    }

    &--secondary {
        color: $white;
        text-decoration: underline;

        &:hover {
            color: $cool-primary;
            text-decoration: none;
        }
    }

    &__list li::marker {
        color: $cool-primary;
    }
}
