@use 'sass:color';
$sidemenu-width: 285px;
$header-mobile-height: 70px;

#sidemenu {
    position: fixed;
    z-index: 800;
    top: $header-mobile-height;
    right: -$sidemenu-width;
    //right: 0;  //still open for development
    width: $sidemenu-width;
    height: calc(100% - #{$header-mobile-height});
    overflow-y: auto;
    border-radius: 4px;
    background-color: rgba($white, 0.95);
    padding-top: 35px;
    padding-left: 25px;
    padding-right: 25px;
    padding-bottom: 35px;
    box-shadow: 0 10px 10px rgba($black, 0.2);
    transition: right 0.3s ease-in-out;

    @include media-breakpoint-down(sm) {
        padding-top: 20px;
    }
}

.sidemenu {
    display: flex;
    flex-direction: column;

    &--show {
        right: 0 !important;
    }

    &__list {
        list-style: none;
        padding-left: 5px;
    }

    &__item {
        margin-bottom: 13px;

        @include media-breakpoint-down(sm) {
            margin-bottom: 0;
            line-height: 22px;
        }
    }

    &__link {
        color: $cool-secondary;
        font-size: 15px;
        font-weight: 500;
        transition: all 0.4s ease;

        @include media-breakpoint-down(sm) {
            line-height: 22px;
        }

        &:hover {
            color: color.adjust($cool-secondary, $lightness: 5%);
            text-decoration: none;
            padding-left: 5px;
        }
    }
}
