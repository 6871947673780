.articles {
    &__card {
    }

    &__img {
        width: 100%;
        height: 220px;
        background-color: lightgrey;
        margin-bottom: 10px;
    }

    &__tags {
        border: 1px solid purple;
        border-radius: 26px;
        background-color: white;
        font-size: 12px;
        padding: 1px 13px;
        position: relative;
        z-index: 2;
        color: $cool-grey;
        margin-right: 3px;
        display: inline-block;
        margin-bottom: 5px;

        &:hover {
            text-decoration: none;
            color: $cool-grey;
        }

        &--empty {
            display: inline-block;
            height: 30px;
            width: 100%;
            content: 'Help ';
            clear: both;
        }
    }

    &__title {
        color: $cool-grey;
        font-size: 22px;
        line-height: 27px;
    }
}
