.section-steps {
    font-size: 1rem;
    background-color: $light-gray;

    @include media-breakpoint-up(lg) {
        font-size: 1.25rem;
    }

    @include media-breakpoint-up(lg) {
        font-size: $global-font-size + 0.25rem;
    }

    h2 {
        margin-bottom: 1.25rem;
        color: $cc-green;

        @include media-breakpoint-up(lg) {
            margin-bottom: 3.125rem;
        }
    }

    h3 {
        font-size: 1.125rem;
        font-weight: 600;
    }

    p {
        margin-bottom: 7px;
        font-size: 1rem;
    }

    .grid-container,
    .container {
        max-width: 66.25rem;
    }
    .ico-steps {
        padding-left: 6.25rem;
        background-repeat: no-repeat;
        background-size: 3.75rem auto;
        background-position: 0.625rem 0;
        margin-bottom: 3rem;

        @include media-breakpoint-down(sm) {
            margin-bottom: 1.5rem;
        }

        @include media-breakpoint-up(lg) {
            padding-left: 9.375rem;
            background-position: 2.5rem 0;
        }

        &.ico-1 {
            background-image: url('../../../images/ico-steps-1-purple.png');

            @include media-breakpoint-up(lg) {
                background-size: 4.25rem auto;
            }
        }

        &.ico-2 {
            background-image: url('../../../images/ico-steps-2-purple.png');

            @include media-breakpoint-up(lg) {
                background-size: 5.125rem auto;
            }
        }

        &.ico-3 {
            background-image: url('../../../images/ico-steps-3-purple.png');

            @include media-breakpoint-up(lg) {
                background-size: 4.75rem auto;
            }
        }

        &.ico-4 {
            background-image: url('../../../images/ico-steps-4-purple.png');

            @include media-breakpoint-up(lg) {
                background-size: 4.313rem auto;
            }
        }
    }
}
