.supplement {
    &__box {
        display: flex;
        justify-content: space-around;
        align-items: center;

        @include media-breakpoint-down(sm) {
            flex-direction: column;
        }
    }

    &__header {
        @include media-breakpoint-down(sm) {
            max-width: 260px;
            margin: 0 auto;
            padding-bottom: 25px;
        }
    }
}
