body {
    scroll-behavior: smooth;
}

.horizontal-line {
    width: 100%;
    height: 1px;

    &.more-payments {
        margin: 0;
        background-color: #82ae33;
        border: unset;
        max-width: unset;
    }
}
