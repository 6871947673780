.inputs {
    &__phone {
        line-height: 19px;
        font-size: 15px;
        font-weight: 400;
        color: $cool-grey;
        min-width: 260px;

        padding-left: 18px;
        padding-right: 22px;
        padding-top: 16px;
        padding-bottom: 16px;

        border: none;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;

        @include media-breakpoint-down(sm) {
            min-width: 200px;
        }
    }

    &__before {
        background-color: rgba($black, 0.17);
        color: $white;
        line-height: 19px;
        font-size: 15px;
        font-weight: 600;
        border: none;
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;

        &:has(.select2-container--open) {
            border-bottom-left-radius: 0;
        }
    }

    &__phone.invalid {
        background-color: #f9eaea;
        border-color: #c32d2c;
        box-shadow: inset 0 0 0 2px #c32d2c;
    }
}
