$triangl-height: 20px;
$triangl-width: 22.5px;

.triangl {
    width: 0;
    height: 0;
    border-style: solid;
    border-color: #ffffff transparent transparent transparent;
    border-width: $triangl-height $triangl-width 0 $triangl-width;

    position: relative;
    left: calc(50% - #{$triangl-width});
    top: -1px;

    &--secondary {
        border-color: $cool-secondary transparent transparent transparent;
    }

    &--third {
        border-color: $cool-third transparent transparent transparent;
    }
}
