.section-orange {
    text-transform: uppercase;
    background-color: $dark-orange;
    color: #fff;
    position: relative;

    &::before {
        content: '';
        border-style: solid;
        border-width: 1.7rem 1.7rem 0 1.7rem;
        border-color: $cc-green transparent transparent transparent;
        display: block;
        height: 0;
        left: 50%;
        transform: translateX(-50%);
        position: absolute;
        top: -1px;
        width: 0;
    }

    &.arrow-white::before {
        border-color: #fff transparent transparent transparent;
    }

    &.arrow-green::before {
        border-color: $cc-green transparent transparent transparent;
    }

    h2 {
        font-weight: 700;
    }

    .phone__wrap {
        @extend .row, .justify-content-center, .align-items-center;
        margin: 0.625rem 0 1.25rem;
    }

    .phone {
        display: flex;
        background-color: #e48c12;
        font-weight: bold;
        text-decoration: none;
        color: $white;
        margin: 0.9rem 0 1.25rem 0.625rem;
        max-width: 21.875rem;
        align-items: center;

        @include media-breakpoint-up(lg) {
            margin: 0;
        }

        span {
            display: inline-block;
            padding: 0 1rem;
        }

        input {
            display: inline-block;
            width: 100%;
            height: 3.25rem;
            padding: 3px;
            margin: 0;
            border: none;
            background: $light-gray;
            border-radius: 0;
            color: $medium-gray;
            font-weight: bold;
            text-align: center;
        }
    }
}
