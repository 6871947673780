.section-when {
    color: black;
    background-color: $light-gray;

    h2 {
        color: $cc-green;

        @include media-breakpoint-down(md) {
            font-size: 1.5rem;
        }
    }

    h3 {
        color: black;
        text-transform: none;
        font-weight: 700;
        font-size: 1.125rem;

        @include media-breakpoint-up(sm) {
            font-size: 1.5rem;
        }

        @include media-breakpoint-up(md) {
            font-size: 1.7rem;
        }
    }

    p {
        font-size: 0.875rem;
        font-weight: 500;

        @include media-breakpoint-up(md) {
            font-size: 1.125rem;
        }
    }

    .clock .space {
        span {
            &.first,
            &.second {
                background-color: $cc-green;
            }
        }
    }

    .time {
        margin-top: 1.875rem;
        max-width: 48.75rem;
        margin-left: auto;
        margin-right: auto;
        background: url('../../../images/ico-time-gray.png') center center no-repeat;

        & > table {
            width: 100%;
            margin-bottom: 1rem;
        }

        &--nobg {
            background: none;
        }

        @include media-breakpoint-up(lg) {
            margin-top: 3.125rem;
            margin-bottom: 4.375rem;
        }

        tr {
            @include media-breakpoint-up(md) {
                display: flex;
            }

            td {
                padding: 0.625rem 0;
                vertical-align: middle;
                text-align: left;
                font-size: 0.875rem;
                font-weight: 500;

                @include media-breakpoint-up(md) {
                    font-size: 1.125rem;
                }
            }

            td:last-child {
                color: $black;
                font-weight: bold;
                text-transform: uppercase;
                text-align: right;
            }

            .line {
                display: none;

                @include media-breakpoint-up(md) {
                    display: block;
                    flex-grow: 1;
                }

                > div {
                    margin: 0 15px;
                    height: 15px;
                    border-bottom: 1px solid #cccccc;
                }
            }
        }
    }

    .clock {
        margin-bottom: 1.875rem;
    }

    .j-link-with-anchor {
        margin: 1.25rem auto 0 auto;
    }
}
