.section-compare {
    &__info {
        @extend .text-right, .mt-4, .d-flex, .justify-content-end, .pr-0;
        font-size: 9px;
    }

    .section-compare__sale {
        @extend .d-flex, .justify-content-center;
        margin: 2rem 0 2rem;
        color: $text-primary;

        @include media-breakpoint-up(md) {
            margin: 1rem 0 1rem;
        }

        .sale__title {
            font-size: 2rem;
            color: $dark-purple;

            @include media-breakpoint-down(sm) {
                font-size: 1.25rem;
            }
        }

        .sticker {
            align-items: center;
            display: flex;
            justify-content: center;
            padding: 0.2rem 1rem;

            &--sale {
                background-color: $light-gray;
                border: 2px dashed $dark-purple;
                border-radius: 0.7rem;
                font-size: 1.375rem;
            }
        }
    }

    &__button {
        @extend .d-flex, .justify-content-center, .w-100, .text-center;
        margin-top: 2rem;
    }
}
