.info {
    &__numbers {
        line-height: 61px;
        font-size: 50px;
        font-weight: 800;
        color: rgba($white, 0.36);
        text-shadow: 0 5px 12px rgba($black, 0.24);
        margin-bottom: 40px;
    }

    &__text {
        line-height: 24px;
        font-size: 20px;
        font-weight: 600;
        color: $white;
        margin-bottom: 10px;
    }

    &__list {
        padding-left: 20px;

        li {
            line-height: 26px;
            font-size: 15px;
            font-weight: 500;
            color: $white;

            a {
                color: $white;
                text-decoration: underline;

                &:hover {
                    color: $white;
                    text-decoration: none;
                }
            }

            &::marker {
                color: $white;
            }
        }
    }

    &__dot {
        list-style: none;
    }
}

.list-style-none {
    list-style: none;
}
