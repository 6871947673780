.section-banks {
    h2 {
        text-transform: none;
        color: $cc-green;
    }

    p {
        font-size: 0.875rem;
        font-weight: 500;
        color: $text-primary;

        @include media-breakpoint-up(md) {
            font-size: 1.125rem;
        }
    }

    .banks {
        margin: 1.25rem 0;

        @include media-breakpoint-up(lg) {
            margin: 2.5rem 0;
        }

        img {
            margin: 0.625rem;
            height: 3.4rem;
        }
    }
}
